import loadable from "@loadable/component";
import React, { useCallback, useEffect, useRef } from 'react';
import { matchPath, Route, Switch, useLocation, withRouter } from "react-router-dom";
import './App.scss';
import i18n from "./i18n";
import { ContentRoute, DefaultRoute } from './layouts';


const ForResident = loadable(() => import("./pages/ForResident/forResident"))
const ForBoard = loadable(() => import("./pages/ForBoard/forBoard"))
const ForJuristicManagement = loadable(() => import("./pages/ForJuristicManagement/forJuristicManagement"))
const ForJuristicAccounting = loadable(() => import("./pages/ForJuristicAccounting/forJuristicAccounting"))

const PMCM = loadable(() => import("./pages/PMCM/PMCM"))

const FoQus = loadable(() => import("./pages/service/foQus"))

const Pricing = loadable(() => import("./pages/pricing/PricingRoutes"))
const Information = loadable(() => import("./pages/information/InformationRoutes"))
// const BlogInfomation = loadable(()=>import("./pages/information/blogInfomation"))


const Company = loadable(() => import("./pages/company/CompanyList"))
const Guard = loadable(() => import("./pages/guard/Guard"))
const Debt = loadable(() => import("./pages/debt/Debt"))
const DebtLaw = loadable(() => import("./pages/debt-law/Debt-law"))
const AIFacial = loadable(() => import("./pages/ai-facial/ai-facial"))
const LPR = loadable(() => import("./pages/LPR/LPR"))
const Keycard = loadable(() => import("./pages/keycard/keycard"))
const EIA = loadable(() => import("./pages/EIA/eia"))
const SvmVsSoftbiz = loadable(() => import("./pages/blogPage/svmVsSoftbiz/svmVsSoftbiz"))
const juristicHousingEstate = loadable(() => import("./pages/juristic-housing-estate/juristic-housing-estate"))
const AccountingService = loadable(() => import("./pages/accounting/accountingService"))
const AuditService = loadable(() => import("./pages/accounting/auditService"))
const VisitorControl = loadable(() => import("./pages/visitorControl/VisitorControl"))
const Faq = loadable(() => import("./pages/faq"))
const About = loadable(() => import("./pages/about"))
const Contact = loadable(() => import("./pages/contact"))
const Privacy = loadable(() => import("./pages/privacy"))
const TermOfUser = loadable(() => import("./pages/termOfUser"))
const ServiceStandard = loadable(() => import("./pages/servicesStandard"))
const UserDelete = loadable(() => import("./pages/userDelete"))
const Customer = loadable(() => import("./pages/customer"))
const TeamSupport = loadable(() => import("./pages/teamSupport/TeamSupport"))
const Sitemap = loadable(() => import("./pages/sitemap/Sitemap"))
const Video = loadable(() => import("./pages/video/VideoRoutes"))

const BecomePartner = loadable(() => import("./pages/become-partner/become-partner"))
const Career = loadable(() => import("./pages/career"))
const Partner = loadable(() => import("./pages/partner/partner"))
const SettingUpJuristic = loadable(() => import("./pages/settingUpJuristic"))

const CareerDetail = loadable(() => import("./pages/career/detail"))

const Register = loadable(() => import("./pages/register/Register"))
const Home = loadable(() => import("./pages/Home/Home"))

const Privilege = loadable(() => import("./pages/privilege/privilege"))

const Seminar = loadable(() => import("./pages/seminar/seminar"))

const Seminar2 = loadable(() => import("./pages/seminar/seminar2"))

const Freshket = loadable(() => import("./pages/freshket/freshket"))

const Switching = loadable(() => import("./pages/switching/switching"))

const Download = loadable(() => import("./pages/download/index"))

const Meeting = loadable(()=> import("./pages/information/meeting/meeting"))
const CommonFee = loadable(()=> import("./pages/information/commonFee/commonFee"))

// const EIA = loadable(() => import("./pages/EIA/eia"))

const NoMatch = loadable(() => import("./pages/error/404"))

function App() {

  const routeRef = useRef()
  const location = useLocation()
  const changeLang = useCallback(() => {
    const defaultLang = 'th'
    const matchLang = matchPath(location.pathname, { path: '/:lang(en)?/' })
    const lang = matchLang?.params?.lang || defaultLang
    setTimeout(() => i18n.changeLanguage(lang, async () => {
      // @see https://github.com/i18next/i18next/blob/master/CHANGELOG.md#1710
      const usedNamespaces = i18n.reportNamespaces?.getUsedNamespaces()
      if (usedNamespaces) await i18n.loadNamespaces(usedNamespaces)
    }), 0)
  }, [location])

  useEffect(() => {
    // Detect languagae changed
    changeLang()

    // Scroll to top on route changed
    const href = (location.pathname + location.search)
    if (routeRef.current !== href) {
      routeRef.current = href
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [location, changeLang])

  return (
    <Switch>
      <DefaultRoute path="/:lang(en)?/for-resident" component={ForResident} />
      <DefaultRoute path="/:lang(en)?/for-board" component={ForBoard} />
      <DefaultRoute path="/:lang(en)?/for-juristic-management" component={ForJuristicManagement} />
      <DefaultRoute path="/:lang(en)?/for-juristic-accounting" component={ForJuristicAccounting} />

      <DefaultRoute path="/:lang(en)?/maintenance-management" component={PMCM} />

      <DefaultRoute path="/:lang(en)?/foQus" component={FoQus} />
      <DefaultRoute path="/:lang(en)?/silverman-guard" component={Guard} />
      <DefaultRoute path="/:lang(en)?/visitor-control" component={VisitorControl} />

      <DefaultRoute path="/:lang(en)?/debt-collection" component={Debt} />
      <DefaultRoute path="/:lang(en)?/debtcollection" component={DebtLaw} />
      <DefaultRoute path="/:lang(en)?/ai-facial" component={AIFacial} />

      <DefaultRoute path="/:lang(en)?/blog/silverman-vs-softbiz" component={SvmVsSoftbiz} />
      <DefaultRoute path="/:lang(en)?/blog/license-plate-recognition-camera" component={LPR} />
      <DefaultRoute path="/:lang(en)?/blog/condo-annual-general-meeting-guidelines" component={Meeting} />
      <DefaultRoute path="/:lang(en)?/blog/what-is-common-fees" component={CommonFee} />
      <DefaultRoute path="/:lang(en)?/blog/ways-preventing-keycard-forgery" component={Keycard} />
      <DefaultRoute path="/:lang(en)?/blog/what-is-eia-environmental-impact-assessment" component={EIA} />






      <DefaultRoute path="/:lang(en)?/juristic-housing" component={juristicHousingEstate} />
      <DefaultRoute path="/:lang(en)?/accounting-service" component={AccountingService} />

      <DefaultRoute path="/:lang(en)?/privilege" component={Privilege} />
      
      {/* <DefaultRoute path="/:lang(en)?/seminar" component={Seminar} /> */}

      <DefaultRoute path="/:lang(en)?/seminar" component={Seminar2} />

      <DefaultRoute path="/:lang(en)?/freshket" component={Freshket} />

      <DefaultRoute path="/:lang(en)?/switching-to-silverman" component={Switching} />

      <DefaultRoute path="/:lang(en)?/audit-service" component={AuditService} />

      <DefaultRoute path="/:lang(en)?/price-and-package" component={Pricing} />
      <DefaultRoute path="/:lang(en)?/blog" component={Information} />

      <DefaultRoute path="/:lang(en)?/juristic-management-company" component={Company} />

      <DefaultRoute path="/:lang(en)?/faq" component={Faq} />
      <DefaultRoute path="/:lang(en)?/about" component={About} />
      <DefaultRoute path="/:lang(en)?/contact" component={Contact} />
      <DefaultRoute path="/:lang(en)?/privacy" component={Privacy} />
      <DefaultRoute path="/:lang(en)?/term-of-user" component={TermOfUser} /> 
      <DefaultRoute path="/:lang(en)?/service-standard" component={ServiceStandard} /> 
      <DefaultRoute path="/:lang(en)?/account-delete" component={UserDelete} /> 
      <DefaultRoute path="/:lang(en)?/customer" component={Customer} />
      <DefaultRoute path="/:lang(en)?/juristic-support-team" component={TeamSupport} />
      <ContentRoute path="/:lang(en)?/sitemap" component={Sitemap} />
      <DefaultRoute path="/:lang(en)?/videos" component={Video} />

      <DefaultRoute path="/:lang(en)?/become-partner" component={BecomePartner} />
      <DefaultRoute path="/:lang(en)?/career/:id" component={CareerDetail} />
      <DefaultRoute path="/:lang(en)?/career" component={Career} />
      <DefaultRoute path="/:lang(en)?/partner-with-us" component={Partner} />
      <DefaultRoute path="/:lang(en)?/settingup-juristic" component={SettingUpJuristic} />
      
      <DefaultRoute path="/:lang(en)?/register" component={Register} />
      <DefaultRoute path="/:lang(en)?/download" component={Download} />
      <DefaultRoute exact path="/:lang(en)?/" component={Home} />
      <Route path={"*"} component={NoMatch} />
    </Switch>
  )

}

export default withRouter(App)
